import { IconMinus, IconPlus } from "@tabler/icons-react";
import React, { useState } from "react";
import { useRentGPU } from "../../utils/gpu.api";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";

function BuyModal({ isOpen, onClose, gpu }) {
  const rentGPU = useRentGPU();

  const { isConnected, address } = useAccount();

  const [sshKeys, setSshKeys] = useState([""]);
  const [vmId, setVmId] = useState("");

  const addKey = () => {
    setSshKeys([...sshKeys, ""]);
  };

  const removeKey = (index) => {
    const newKeys = sshKeys.filter((_, i) => i !== index);
    setSshKeys(newKeys);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }
    rentGPU
      ?.mutateAsync({
        account: address,
        customSshKeys: sshKeys,
        vmId: vmId,
        maxHours: 100,
        personalVmId: gpu?._id,
      })
      .then((result) => {
        toast.success("GPU bought successfully!");
        onClose();
        setSshKeys([""]);
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong!");
      });
  };

  return (
    isOpen && (
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Buy GPU
                      </h3>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="flex w-100 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 sm:max-w-md">
                      <input
                        type="text"
                        name="key"
                        required
                        value={vmId}
                        onChange={(e) => {
                          setVmId(e.target.value);
                        }}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Enter unique vmid"
                      />
                    </div>
                    {sshKeys?.map((ssh, idx) => (
                      <div className="flex gap-2 mt-3">
                        <div className="flex w-100 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 sm:max-w-md">
                          <input
                            type="text"
                            name="key"
                            required
                            value={ssh}
                            onChange={(e) => {
                              const newKeys = [...sshKeys];
                              newKeys[idx] = e.target.value;
                              setSshKeys(newKeys);
                            }}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Enter your ssh key..."
                          />
                        </div>
                        {sshKeys?.length > 1 && (
                          <button
                            onClick={() => removeKey(idx)}
                            className="rounded-md bg-white px-3 py-2 shadow-sm ring-1 ring-gray-300"
                          >
                            <IconMinus color="#000" size="14px" />
                          </button>
                        )}
                        <button
                          onClick={addKey}
                          className="rounded-md bg-white px-3 py-2 shadow-sm ring-1 ring-gray-300"
                        >
                          <IconPlus color="#000" size="14px" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-[#76BC00] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#76BC00] sm:ml-3 sm:w-auto"
                    disabled={rentGPU?.isPending}
                  >
                    {rentGPU?.isPending ? "Buying..." : "Buy"}
                  </button>
                  <button
                    onClick={onClose}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default BuyModal;
