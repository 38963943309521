import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/login", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response?.token) {
        localStorage.setItem("token", response?.token?.access_token);
        queryClient.invalidateQueries({ queryKey: ["token"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useGetLoggedInUser = () =>
  useQuery({
    queryKey: ["getLoggedInUser"],
    queryFn: async () => {
      const response = await client(`auth/getLoggedInUser`);
      console.log("getLoggedInUser response", response);
      return response?.[0];
    },
  });

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      localStorage.clear();
      queryClient.invalidateQueries({ queryKey: ["token"] });
    },
  });
};

export const useTopupAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (topupData) => {
      const response = await client("auth/topupAccount", {
        method: "POST",
        data: topupData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      return response;
    },
  });
};
