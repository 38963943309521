import React, { useState } from "react";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useTopupAccount } from "../../utils/auth.api";
import { readContract, writeContract } from "@wagmi/core";
import {
  cloudWorkAbi,
  cloudWorkAdress,
  tokenContractAbi,
  tokenContractAddress,
  walletConfig,
} from "../../config/wallet.config";
import { fromWei, toWei } from "../../config/methods";

function TopupModal({ isOpen, onClose }) {
  const topupAccount = useTopupAccount();

  const { isConnected, address } = useAccount();

  const [amount, setAmount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const completeTopUp = async () => {
    console.log("topup account");
    try {
      const hashInfo = await writeContract(walletConfig, {
        abi: cloudWorkAbi,
        functionName: "pay",
        address: cloudWorkAdress,
        args: [tokenContractAddress, toWei(amount, 6), "topping up account"],
      });

      console.log("hash info 123", hashInfo);
      topupAccount
        ?.mutateAsync({
          address: address,
          amount: Number(amount),
          transactionHash: hashInfo,
        })
        .then((result) => {
          setIsProcessing(false);
          setAmount(0);
          onClose();
          toast.success("Account topped up successfully!");
        })
        .catch((err) => {
          setIsProcessing(false);
          toast.error(err.message || "Something went wrong!");
        });
    } catch (e) {
      setIsProcessing(false);
      toast.error(e.message || "Something went wrong!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      toast.error("Please login first!");
      return;
    }
    if (Number(amount) <= 0) {
      toast.error("Please enter a valid amount!");
      return;
    }
    try {
      setIsProcessing(true);
      const allowanceInfo = await readContract(walletConfig, {
        abi: tokenContractAbi,
        functionName: "allowance",
        address: tokenContractAddress,
        args: [address, cloudWorkAdress],
      });

      if (
        Number(fromWei(allowanceInfo?.toString(), 6)?.toString()) <
        Number(amount)
      ) {
        console.log("allowance", allowanceInfo?.toString());
        console.log("amount", amount);
        console.log("approving amount");
        const hashInfo = await writeContract(walletConfig, {
          abi: tokenContractAbi,
          functionName: "approve",
          address: tokenContractAddress,
          args: [cloudWorkAdress, toWei(amount, 6)],
        });

        console.log("hash info", hashInfo);
        // wait for 10 seconds
        await new Promise((resolve) => setTimeout(resolve, 10000));
        completeTopUp();
      } else {
        completeTopUp();
      }

      console.log("allowance e", allowanceInfo?.toString());
    } catch (err) {
      setIsProcessing(false);
      toast.error(err.message || "Something went wrong!");
    }
  };

  return (
    isOpen && (
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Topup your account
                      </h3>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="flex w-100 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 sm:max-w-md">
                      <input
                        type="text"
                        name="key"
                        required
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Enter amount"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-[#76BC00] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#76BC00] sm:ml-3 sm:w-auto"
                    disabled={topupAccount?.isPending || isProcessing}
                  >
                    {topupAccount?.isPending || isProcessing
                      ? "Processing..."
                      : "Topup"}
                  </button>
                  <button
                    disabled={topupAccount?.isPending || isProcessing}
                    onClick={onClose}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default TopupModal;
