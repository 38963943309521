import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetGPUs = () =>
  useQuery({
    queryKey: ["getGPUs"],
    queryFn: async () => {
      const response = await client(`gpu`);
      return response;
    },
  });

export const useGetVMs = () =>
  useQuery({
    queryKey: ["getVMs"],
    queryFn: async () => {
      const response = await client(`vms`);
      return response;
    },
  });

export const useRentGPU = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload) => {
      const response = await client(`gpu/rent`, {
        method: "POST",
        data: payload,
      });
      if (response) {
        queryClient.invalidateQueries({ queryKey: ["getGPUs"] });
        queryClient.invalidateQueries({ queryKey: ["getVMs"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useCancelGPU = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      const response = await client(`gpu/${id}/cancel`, {
        method: "PUT",
      });
      if (response) {
        queryClient.invalidateQueries({ queryKey: ["getGPUs"] });
        queryClient.invalidateQueries({ queryKey: ["getVMs"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};
